@font-face {
  font-family: 'AcuminVariableConcept';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/AcuminVariableConcept.otf'); /* IE9 Compat Modes */
  }

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  box-sizing: border-box;
}

body, button, input, select, textarea {
  font-family: 'AcuminVariableConcept', 'Nunito',Helvetica, Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
}

input[type="checkbox" i] {
  margin: 3px 3px 3px 4px;
}

input[type="checkbox" i] {
  -webkit-appearance: checkbox;
  box-sizing: border-box;
}

input[type="radio" i], input[type="checkbox" i] {
  background-color: initial;
  margin: 3px 0.5ex;
  padding: initial;
  border: initial;
}

input[type=checkbox], input[type=radio] {
  vertical-align: baseline;
}

p {
  font-size: 22px;
  font-weight: 300;
}

h1{
  font-size: 60px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: normal;
}

h2{
  font-size: 28px;
  font-weight: 600;
}

h4{
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
}

h6{
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

ul {
  list-style: none;
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 40px;
}

li {
  margin: 0;
  padding: 0;
  display: list-item;
  text-align: -webkit-match-parent;
}

a {
  color: #3273dc;
  cursor: pointer;
  text-decoration: none;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

form {
  margin: 25px 0;
}

@media (min-width: 500px) {
    h6 {
        margin-top: 250px;
    }
}

@media (max-width: 499px) {
    h1{
        font-size: 40px;
    }

    h4{
        font-size: 18px;
    }
}

*, *::after, *::before {
  box-sizing: inherit;
}
@media (max-width: 499px) {
  /* width */
  ::-webkit-scrollbar {
    width: 1px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
}
.is-1 {
  flex: none !important;
  width: 8.33333% !important;
}

.is-2 {
  flex: none !important;
  width: 16.66667% !important;
}

.is-3 {
  flex: none !important;
  width: 25% !important;
}

.is-4 {
  flex: none !important;
  width: 33.33333% !important;
}

.is-5 {
  flex: none !important;
  width: 41.66667% !important;
}

.is-6 {
  flex: none !important;
  width: 50% !important;
}

.is-7 {
  flex: none !important;
  width: 58.33333% !important;
}

.is-8 {
  flex: none !important;
  width: 66.66667% !important;
}

.is-9 {
  flex: none !important;
  width: 75% !important;
}

.is-10 {
  flex: none !important;
  width: 83.33333% !important;
}

.is-11 {
  flex: none !important;
  width: 91.66667% !important;
}

.is-12 {
  flex: none !important;
  width: 100% !important;
}

.slide{
  background: transparent !important;
}

.carousel .control-dots {
  padding: 0;
}

.carousel .slider-wrapper {
  width: 90vw !important;
}
.carousel .control-dots .dot{
  background-color: #339966 !important;
}

.carousel .control-dots .dot .selected{
  background-color: #bddccd !important;
  outline-color: transparent !important;
}

.carousel .control-dots .dot.selected:focus{
  outline-color: transparent !important;
}

.slider-wrapper .axis-horizontal {
  width: 90vw !important;
}

.carousel .control-dots {
}

@media(max-width:361px) {
  .carousel .control-dots {
    bottom: 10px !important;
  }
  .carousel.carousel-slider{
    padding-bottom: 30px;
  }
}
.react-pdf__Document{
  position: absolute;
  bottom: 50px;
  top: 0;
  right: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.react-pdf__Page__canvas{
  min-height: calc(100vh - 50px) !important;
  max-width: 100vw !important;  
}