.SpinnerContent {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 80;
    background-color: rgba(18, 25, 29, 0.21);
    height: 100hv;
    min-height: 100%;
}

.SpinnerCard {
    top: 0 !important;
    position: absolute;
    background-color: transparent;
    margin-top: 0;
    z-index: 30;
}

.SpinnerAdmin {
    top: 56px;
    left: 200px;
}

.Loader {
    display: inline-block;
    width: 64px;
    height: 64px;
}

.Loader:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #256993;
    border-color: #256993 transparent #256993 transparent;
    animation: load2 1.2s linear infinite;
}

.SpinnerBottom {
    align-items: flex-start;
    z-index: 89;
    left: 0;
    right: 0;
    bottom: 56px;
    background-color: transparent !important;
}

@media (max-width: 500px) {
    .SpinnerAdmin {
        left: 0;
        bottom: 56px;
        min-height: auto;
    }
}

@-webkit-keyframes load2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load2 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}